import inside from 'point-in-polygon';
import { serializeRestaurantSchedule } from './restaurantScheduleSerializer';

export class City {
  constructor(cityData) {
    this.pk = cityData.pk;
    this.title = cityData.title;
    this.border = cityData.border;
    this.ownDeliveryAvailable = cityData.own_delivery_available;
    this.isReducedAreaActive = cityData.is_reduced_area_active;
    this.hotTariff = cityData.hot_tariff;
    this.paymentMethods = cityData.payment_methods;
    this.schedule = serializeRestaurantSchedule(cityData.schedule || []);
    this.service_fee = {
      amount: cityData.service_fee.amount ?? 0,
      title: cityData.service_fee.title ?? '',
      title_kz: cityData.service_fee.title_kz ?? '',
      description: cityData.service_fee.description ?? '',
      description_kz: cityData.service_fee.description_kz ?? '',
      is_enabled_for_vd: cityData.service_fee.is_enabled_for_vd ?? '',
      is_enabled_for_od: cityData.service_fee.is_enabled_for_od ?? '',
    };
  }

  get mappedBorder() {
    return this.border.map(
      (latLong) => ([latLong.latitude, latLong.longitude]),
    );
  }

  containsPoint(lng, lat) {
    return inside([lat, lng], this.mappedBorder);
  }
}

export const serializeCity = (city) => new City(city);

export const serializeCityList = (cities) => cities.map(serializeCity);

export const serializeCityListShort = (cities) => cities
  .map((city) => ({ title: city.title, id: Number(city.pk) }));
