export const defaultStates = [{ title: 'Активно', value: 'active' }, { title: 'Отключено', value: 'inactive' }];

const defaultStyles = {
  new: { title: 'Новая заявка', color: '#F2EFFC', textColor: '#5B32D3' },
  processed: { title: 'Обработан', color: '#E5FFEA', textColor: '#4CAF54' },
  active: { title: 'Активно', color: '#E5FFEA', textColor: '#4CAF54' },
  processing: { title: 'В обработке', color: '#FFF1EE', textColor: '#EE6002' },
  inactive: { title: 'Отключено', color: '#FFF1EE', textColor: '#EE6002' },
  deleted: { title: 'Удалено', color: '#FFEBEE', textColor: '#E53935' },
  expired: { title: 'Срок истек', color: '#FAFAFB', textColor: '#4D4F67' },
};

export const allStateParams = {
  active: defaultStyles.active,
  enabled: defaultStyles.active,
  inactive: defaultStyles.inactive,
  disabled: defaultStyles.inactive,
  removed: defaultStyles.deleted,
  expired: defaultStyles.expired,
  new: defaultStyles.new,
  in_processing: defaultStyles.processing,
  processed: defaultStyles.processed,
};

export const storyStates = {
  active: defaultStyles.active,
  inactive: defaultStyles.inactive,
  removed: defaultStyles.deleted,
  expired: defaultStyles.expired,
};

export const suggestionStates = {
  enabled: defaultStyles.active,
  disabled: defaultStyles.inactive,
  expired: defaultStyles.expired,
};

export const partnerStates = {
  active: defaultStyles.active,
  inactive: defaultStyles.inactive,
};

export const states = {
  food: [
    { title: 'Активно', value: 'active', color: '#786FDB' },
    { title: 'Отключено', value: 'sold_out', color: '#B3B3BE' },
    { title: 'Удалено', value: 'removed', color: '#E43539' },
  ],
  foodType: [
    { title: 'Активно', value: 'active', color: '#786FDB' },
    { title: 'Отключено', value: 'sold_out', color: '#B3B3BE' },
    { title: 'Удалено', value: 'removed', color: '#E43539' },
  ],
  cartEvent: [
    { title: 'Активно', value: 'active', color: '#786FDB' },
    { title: 'Отключено', value: 'inactive', color: '#B3B3BE' },
    { title: 'Удалено', value: 'removed', color: '#E43539' },
  ],
  cartEventAll: [
    { title: 'Все', value: null },
    { title: 'Активно', value: 'active', color: '#786FDB' },
    { title: 'Отключено', value: 'inactive', color: '#B3B3BE' },
    { title: 'Удалено', value: 'removed', color: '#E43539' },
  ],
  all: [
    { title: 'Все', value: null },
    { title: 'Активно', value: 'active' },
    { title: 'Отключено', value: 'sold_out' },
    { title: 'Удалено', value: 'removed' },
  ],
  titles: {
    active: 'Активно',
    sold_out: 'Отключено',
    removed: 'Удалено',
    inactive: 'Отключено',
  },
  colors: {
    active: '#786FDB',
    sold_out: '#B3B3BE',
    removed: '#E43539',
    inactive: '#B3B3BE',
  },
  synonymsType: [
    { value: 'restaurant', title: 'Заведение' },
    { value: 'food', title: 'Блюдо ' },
  ],
  synonymsTypeAll: [
    { value: null, title: 'Все' },
    { value: 'restaurant', title: 'Заведение' },
    { value: 'food', title: 'Блюдо ' },
  ],
  tagsType: [
    { value: 'or', title: 'Одна из' },
    { value: 'and', title: 'Должны быть все' },
  ],
  visible: [
    { title: 'Да', value: true, color: '#786FDB' },
    { title: 'Нет', value: false, color: '#786FDB' },
  ],
  visibleAll: [
    { title: 'Все', value: null },
    { title: 'Да', value: true, color: '#786FDB' },
    { title: 'Нет', value: false, color: '#786FDB' },
  ],

};


export const restaurantStates = [
  { value: 'active', text: 'Активный' },
  { value: 'filling_content', text: 'Заполняется контент' },
  { value: 'delivery_does_not_work', text: 'Доставка не работает' },
  { value: 'on_automation', text: 'На автоматизации' },
  { value: 'closed_for_price_change', text: 'Закрыт на корректировку меню' },
  { value: 'closed_on_schedule', text: 'Закрыт по расписанию' },
  { value: 'closed_for_repairs', text: 'Закрыт на ремонт' },
  { value: 'does_not_respond', text: 'Не отвечают' },
  { value: 'closed_by_themselves', text: 'Поставили себя на стоп' },
  { value: 'not_working_with_us', text: 'Не сотрудничают с нами' },
];

export default states;
